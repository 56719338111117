<template>
    <div>
        <div class="table_content">
            <!-- 表格内容 -->
            <commonTitle></commonTitle>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm"
                     element-loading-text="增加小区过程中..."
                     v-loading="loading">
                <el-form-item label="小区名称" prop="villageName">
                    <el-input size="small" v-model="ruleForm.villageName" placeholder="请输入小区名称"></el-input>
                </el-form-item>
                <el-form-item label="小区编号" prop="villageCard">
                    <el-input size="small" onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                              v-model="ruleForm.villageCard" placeholder="请输入小区编号"></el-input>
                </el-form-item>
                <el-form-item label="物业单位">
                    <el-col :span="24">
                        <el-select size="small" v-model="ruleForm.tenementId" placeholder="请选择物业单位" style="width: 100%"
                                   filterable clearable>
                            <el-option
                                    v-for="item in orgList"
                                    :key="item.orgId"
                                    :label="item.orgName"
                                    :value="item.orgId">
                            </el-option>
                        </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item label="建筑面积" prop="coveredarea">
                    <el-input size="small" v-model.number="ruleForm.coveredarea" autocomplete="off"
                              placeholder="请输入小区建筑面积"></el-input>
                </el-form-item>
                <el-form-item label="占地面积" prop="floorspace">
                    <el-input size="small" type="text" v-model.number="ruleForm.floorspace"
                              placeholder="请输入小区占地面积"></el-input>
                </el-form-item>
                <el-form-item label="建立时间" prop="settingTime">
                    <el-col :span="24">
                        <el-date-picker size="small" type="date" placeholder="选择小区建立时间" v-model="ruleForm.settingTime"
                                        value-format="yyyy-MM-dd"
                                        style="width: 100%;"></el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" @click="submitForm('ruleForm')">保存</el-button>
                    <el-button size="small" @click="back">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import qs from "qs"
    import commonTitle from "../table/commonTitle";

    export default {
        name: "addVillage",
        components: {
            commonTitle
        },
        data() {
            var checkVillageNum = (rule, value, callback) => {
                if (value) {
                    if (/[\u4E00-\u9FA5]/g.test(value)) {
                        callback(new Error('编号不能输入汉字'));
                    } else {
                        callback();
                    }
                }
                callback();
            }
            return {
                ruleForm: {
                    villageName: '',
                    villageCard: '',
                    coveredarea: '', //建筑面积
                    settingTime: '',
                    floorspace: '',  //占地面积
                    parentId: 1, //地区
                    provinceId: '',//选中的省份id
                    cityId: '',//选中城市id
                    countyId: '',//选中的县id
                    developersId: '',//开发商Id
                    tenementId: '',//物业id
                },
                rules: {
                    villageName: [
                        {required: true, message: '请输入小区名称', trigger: 'blur'},
                        //  {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
                    ],
                    villageCard: [
                        {required: true, message: '请输入小区编号', trigger: 'change'},
                        {validator: checkVillageNum, trigger: 'change'}
                    ],
                    provinceId: [
                        {required: true, message: '请选择省份', trigger: 'change'}
                    ],
                    cityId: [
                        {required: true, message: '请选择所属城市', trigger: 'change'}
                    ],
                    countyId: [
                        {required: true, message: '请选择县', trigger: 'change'}
                    ],
                    coveredarea: [
                        {required: true, message: '请输入建筑面积', trigger: 'blur'},
                        {type: 'number', message: '建筑面积必须为数字值'}
                    ],
                    floorspace: [
                        {required: true, message: '请输入占地面积', trigger: 'change'},
                        {type: 'number', message: '占地面积必须为数字值'}
                    ],
                    settingTime: [
                        {required: true, message: '请选择小区建立时间', trigger: 'change'}
                    ]
                },
                provinceList: [],  //省
                cityList: [],        //市
                countyList: [],         //县/区
                orgList: [],//机构数据
                url: this.$Config.base_server,
                loading: false
            };
        }, methods: {
            submitForm(formName) {
                var _this = this;
                //立即创建按钮
                this.$refs[formName].validate(function (valid) {
                    if (valid) {
                        //开启加载样式
                        _this.loading = true;
                        //开发商
                        _this.ruleForm.developersId = JSON.parse(localStorage.getItem("user")).id;
                        $.ajax({
                            url: _this.url + "/api-public/opevillage/save?access_token=" + localStorage.getItem("token"),
                            type: 'post',
                            data: JSON.stringify(_this.ruleForm),
                            contentType: 'application/json',
                            success: function (result) {
                                if (result.resp_code == 0) {
                                    _this.loading = false;
                                    //成功后
                                    _this.$confirm('小区添加成功,是否还要继续添加?', '提示', {
                                        confirmButtonText: '继续',
                                        cancelButtonText: '不在继续',
                                        type: 'warning'
                                    }).then(function () {
                                        //清空表单信息
                                        // _this.$refs[formName].resetFields();
                                        localStorage.setItem("villageData", JSON.stringify(result.datas));
                                        _this.$router.push({path: 'addTower', params:{villageData: result.datas}});
                                    }).catch(function () {
                                        _this.$router.go(-1);//返回上一级路由
                                    });
                                } else {
                                    _this.loading = false;
                                    //添加失败
                                    _this.$message({
                                        message: result.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'error'
                                    });
                                }
                            }
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }, back() {
                //返回按钮
                this.$router.go(-1);
            }
        }, created() {
            //初始化数据
            var _this = this; //声明一个变量指向Vue实例this，保证作用域一致
            const data = {
                type: '0'
            };
            $.ajax({
                url: _this.url + "/api-public/opeorg/list?access_token=" + localStorage.getItem("token"),
                data: data,
                contentType: 'application/json',
                success: function (res) {
                    _this.orgList = res.data;
                }
            });

        }
    }
</script>

<style scoped>

    .el-form {
        width: 600px;
        margin: 0 auto;
    }

</style>